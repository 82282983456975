<template>
  <div>
      <v-select v-model="tax_id" :options="taxes" :name="nameBrowse" label='name' :reduce="e => e.id" @input='changeValue()'></v-select>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import vSelect from 'vue-select'

export default {
  props: ['value','label','vValidate','dataVvAs','name','params'],
  components:{
    vSelect
  },
  data(){
    return {
      nameBrowse: "",
      car_factory_id : null,
      currentPage: 1,
      tax_id : null,
      taxes : [],
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.changeValue(val)
      } else {
        this.nameBrowse = ""
      }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  async mounted() {
    this.getData()
    if(this.value) {
        this.tax_id = this.value
        this.changeValue()
    }
  },
  methods:{
    async changeValue(){
      setTimeout( () => {
          if(this.tax_id) {
              let data = this.taxes.find(x => x.id == this.tax_id)
              this.$emit('input', data.id)
              this.$emit('on-change', data)
          }
      }, 200)
    },
    ...mapActions({
      dispatchIndex: 'taxes/all'
    }),
    async getData(){
      let {data} = await this.dispatchIndex()
      this.taxes = data
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>