<template>
  <form-create :id="$route.params.id" @on-success="$router.push({name: 'invoices'})">
    <template v-slot:footer>
      <vs-button :to="{name:'invoices'}" type="border" color="warning">Back</vs-button>
    </template>
  </form-create>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  }
}
</script>

<style>

</style>