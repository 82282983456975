<template>
  <div class="vx-row">
    <div class="vx-col lg:w-1/2 sm:w-full md:w-full  mb-base">
      <vx-card title="Invoice">
        <!-- FORM START -->
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-company class="w-full" v-validate="'required'" name="company_id" label="Company" v-model="company_id"></browse-company>
            <span class="text-danger text-sm" v-show="errors.has('company_id')">{{errors.first('company_id')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <browse-contact class="w-full" v-validate="'required'" name="contact_bill_id" label="Customer's Billing" v-model="contact_bill_id"></browse-contact>
            <span class="text-danger text-sm" v-show="errors.has('contact_bill_id')">{{errors.first('contact_bill_id')}}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-full md:w-1/2">
            <small class="ml-1">Invoice Date</small>
            <flat-pickr :config="flatPickrConfig" class="w-full" v-model="date_transaction"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-full md:w-1/2">
            <small class="ml-1">Invoice Due Date</small>
            <flat-pickr :config="flatPickrConfig" class="w-full" v-model="due_date"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col sm:w-full md:w-1/2">
            <browse-job-order :params="{company_id:company_id, is_bill: true, bill_id: contact_bill_id, is_invoice: false, exclude: details.map(e => e.id)}" @on-change="jo_change" label="Choose Job Order" v-model="job_order_id"></browse-job-order>
          </div>
        </div>
        <!-- FORM END -->
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Invoice's Item">
        <vs-table :data="details" class="mb-3">
          <template slot="thead">
            <vs-th>Job Order No. / Cost Name</vs-th>
            <vs-th>Consignee</vs-th>
            <vs-th>Route</vs-th>
            <vs-th>Vehicle Type</vs-th>
            <vs-th>Price</vs-th>
            <vs-th>Taxes and Discount</vs-th>
            <vs-th>Total Price</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr">
              <vs-td>{{tr.code}}</vs-td>
              <vs-td label="Consignee">{{tr.consignee}}</vs-td>
              <vs-td label="Route">{{tr.route}}</vs-td>
              <vs-td label="Vehicle Type">{{tr.vehicle_type}}</vs-td>
              <vs-td label="Price" class='text-right'>{{tr.total_price | numFormat}}</vs-td>
              <vs-td label="Taxes and Discount" class='text-right'>{{ tr.tax_total  |numFormat }} / {{ tr.discount_total  |numFormat }}</vs-td>
              <vs-td label="Total Price" class='text-right'>{{ tr.subtotal | numFormat }}</vs-td>
              <vs-td width="10">
                <div class="flex">
                  <vs-button size="small" type="line" color="primary" @click="changeDiscount(tr.id)" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button size="small" type="line" color="danger" @click="deletes(indextr)" icon-pack="feather" icon="icon-trash"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="flex">
          <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save" class='mr-1'>Save</vs-button>&nbsp;
          <slot name="footer"></slot>
        </div>
      </vx-card>
    </div>

    <vs-prompt  @accept="acceptDiscount" title='Change taxes and discount' :active.sync="actived_discount">
        <div>
            <small>Use Percent ?</small>
            <div class="mt-1">
                <input class='mr-2' type='radio' value='1' vs-value='1' name='use_percent' v-model="detailData.use_percent">
                <span class='mr-6'>Yes</span>
                <input class='mr-2' type='radio' value='0' vs-value='0' name='use_percent' v-model="detailData.use_percent">
                <span class='mr-6'>No</span>
            </div>
        </div>

        <div v-if='detailData.use_percent == 1'>
            <div class='mb-1'>
                Discount ( % )
            </div>
            <vs-input class='w-full' type='number' v-model="detailData.percent" placeholder=""></vs-input>
        </div>


        <div v-if='detailData.use_percent != 1'>
            <div class='mb-1'>
                Discount
            </div>
            <vs-input class='w-full' type='number' v-model="detailData.discount" placeholder=""></vs-input>
        </div>

        <vs-divider></vs-divider>
        <small>Taxes</small>
        <span  v-for='(tax, i) in detailData.taxArray' :key='i'>
            <browse-taxes class='mt-2' v-model='detailData.taxArray[i].id'></browse-taxes>
        </span>


    </vs-prompt>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import BrowseTaxes from '@browse/Taxes.vue'
import BrowseCompany from '@browse/Company.vue'
import BrowseContact from '@browse/Contact.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BrowseJobOrder from '@browse/JobOrder.vue';
import initdata from './init'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  components:{
    BrowseTaxes,
    BrowseCompany,
    BrowseContact,
    flatPickr,
    BrowseJobOrder
  },
  data(){
    return {
      detailData : {
            discount : 0,
            taxArray : []
      },
      actived_discount : false,
      company_id: null,
      contact_bill_id: null,
      date_transaction: new Date(),
      due_date: new Date(),
      job_order_id: null,
      details: [],
      flatPickrConfig: {
        altFormat: 'j M Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'invoices/store',
      dispatchUpdate: 'invoices/update',
      dispatchSubtotal: 'invoices/countSubtotal',
      dispatchDiscount: 'invoices/countDiscount',
      dispatchTaxes: 'invoices/countTaxes',
      dispatchShow: 'invoices/show',
      dispatchShowDetail: 'invoices/show_detail',
      dispatchShowTaxes: 'invoice_details/showTaxes'
    }),
    async countSubtotal(payload = {}, fn = () => {}) {
        payload.slug = 'subtotal'
        try {
            this.$vs.loading()
            let {data} = await this.dispatchSubtotal(payload) 
            this.$vs.loading.close()
            fn(data)

        } catch (e) {
            this.$vs.loading.close()
            // console.log(e)
        }
    },
    async showTaxes(invoice_id, invoice_detail_id, fn = () => {}) {
        try {
            let payload = {}
            payload.invoice_detail_id = invoice_detail_id
            payload.invoice_id = invoice_id
            let {data} = await this.dispatchShowTaxes(payload) 
            fn(data)
        } catch (e) {
            // console.log(e)
        }
    },
    async countDiscount(payload = {}, fn = () => {}) {
        try {
            let {data} = await this.dispatchDiscount(payload) 
            fn(data)

        } catch (e) {
            // console.log(e)
        }
    },
    async countTaxes(payload = {}, fn = () => {}) {
        try {
            let {data} = await this.dispatchTaxes(payload) 
            fn(data)

        } catch (e) {
            // console.log(e)
        }
    },
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          company_id: this.company_id,
          contact_bill_id: this.contact_bill_id,
          date_transaction: this.date_transaction,
          due_date: this.due_date,
          job_order_id: this.job_order_id,
          details: this.details
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          if(error.status == 422) {
                  for(let x in error.data) {
                      this.$vs.notify({
                        title: 'Oops!',
                        text: error.data[x][0],
                        color: 'danger'
                      })
                      break
                  }
            } else {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data.message,
                    color: 'danger'
                  })
            }
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.company_id = data.company_id
      this.contact_bill_id = data.contact_bill_id
      this.date_transaction = data.date_transaction
      this.due_date = data.due_date
      let detail = await this.dispatchShowDetail(this.id)
      let details = detail.data
      for(let i in details) {
        details[i].subtotal = details[i].total_price
        details[i].total_price = details[i].price
        details[i].discount_total = details[i].discount
        details[i].discount = {
            discount_total : details[i].discount,
            discount : details[i].discount,
            nominal : details[i].discount,
            percent : details[i].percent,
            use_percent : details[i].use_percent
        } 
        this.showTaxes(this.$route.params.id, details[i].id, (data) => {
            details[i].taxes = data.map((d) => {
                d.id = d.tax_id
                return d
            })
        })
      }
      this.details = details

    },
    jo_change(data){
      // console.log(data)
      let payload = {job_order_id : data.id}
      if(!this.company_id) {
        this.company_id = data.company_id
      }
      this.countSubtotal(payload, (d) => {
          let id = Math.round(Math.random() * 999999)
          let params = {
            "id" : id,
            job_order_id: data.id,
            cost_id: null,
            name: `${data.route} - ${data.mode} - ${data.vehicle_type}`,
            qty: 1,
            price: parseFloat(data.total_price),
            total_price: parseFloat(data.total_price),
            description: "",
            route: data.route,
            mode: data.mode,
            vehicle_type: data.vehicle_type,
            consignee: data.consignee,
            code: data.code,
            taxes: initdata.taxArray,
            tax_total : 0,
            discount_total : 0,
            discount: Object.assign({}, initdata.discount)
          }
          params.subtotal = d.subtotal
          this.details.push(params)
      })
      this.job_order_id = null
      
    },
    deletes(row){
      this.details.splice(row, 1)
    },
    changeDiscount(id) {
        if(id) {
            let dt = this.details.find(x => x.id == id)
            if(dt) {
                this.detailData.discount = dt.discount.nominal ? dt.discount.nominal : 0
                this.detailData.use_percent = parseInt(dt.discount.use_percent)
                this.detailData.percent = dt.discount.percent
                this.detailData.taxArray = dt.taxes
                this.detailData = Object.assign({}, this.detailData)
                this.actived_discount = true
            }
        }
        this.detail_id = id
    },
    acceptDiscount() {
        if(this.detail_id) {
            let idx = this.details.findIndex(x => x.id == this.detail_id)
            if(idx > -1) {
                let payload = {}
                payload.job_order_id = this.details[idx].job_order_id
                payload.discount = this.detailData.discount
                payload.percent = this.detailData.percent
                payload.use_percent = this.detailData.use_percent
                payload.taxes = this.detailData.taxArray
                this.$vs.loading()
                this.countDiscount(payload, (e) => {
                    this.$vs.loading.close()
                    this.details[idx].discount.nominal = parseFloat(this.detailData.discount)
                    this.details[idx].discount.use_percent = parseInt(this.detailData.use_percent)
                    this.details[idx].discount.percent = parseFloat(this.detailData.percent)
                    this.details[idx].discount.discount = parseFloat(e.discount_total)
                    this.details[idx].discount_total = parseFloat(e.discount_total)
                    this.countTaxes(payload, (e) => {
                        this.details[idx].taxes = this.detailData.taxArray
                        this.details[idx].tax_total = parseFloat(e.tax_total)
                        this.details[idx] = Object.assign({}, this.details[idx])
                    })
                })


                this.countSubtotal(payload, (d) => {
                    this.details[idx].subtotal = d.subtotal
                })
                
            }
        }
        this.detail_id = null
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>